import { render, staticRenderFns } from "./Selection.vue?vue&type=template&id=e0a0ac12&scoped=true&lang=pug&"
import script from "./Selection.vue?vue&type=script&lang=ts&"
export * from "./Selection.vue?vue&type=script&lang=ts&"
import style0 from "./Selection.vue?vue&type=style&index=0&id=e0a0ac12&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0a0ac12",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VMain})














// CORE
import { Component, Vue } from 'vue-property-decorator'

// COMPONENTS
import RoleSelectionCard from '@/components/cards/RoleSelectionCard.vue'
import IntermarkLabel from '@/components/IntermarkLabel.vue'

// INTERFACES
import { INameValueItem } from '@/store/types'

// STORE
import AuthModule from '@/store/modules/auth'
import ProfileModule from '@/store/modules/profile'

@Component({
  components: {
    RoleSelectionCard,
    IntermarkLabel,
  },
})
export default class RoleSelection extends Vue {
  private get isRoleChangePage (): boolean {
    return this.$route.name === 'role'
  }

  private get roles (): INameValueItem[] {
    return ProfileModule.userRoles
  }

  private created () {
    if (this.isRoleChangePage) {
      const isOneCar = ProfileModule.userResource?.cars?.length > 0
      const isOneCarspark = ProfileModule.userResource?.carParks?.length > 0
      const isOneCarsgarage = ProfileModule.userResource?.carGarages?.length > 0

      if (isOneCar !== isOneCarspark) {
        this.$router.push({
          name: 'role.entity',
          params: {
            role: isOneCarspark ? 'fleet' : 'driver',
          },
        })
      }

      if (isOneCar !== isOneCarsgarage) {
        this.$router.push({
          name: 'role.entity',
          params: {
            role: isOneCarsgarage ? 'mechanic' : 'driver',
          },
        })
      }
    }
  }

  private handleSubmit (email: string) {
    AuthModule.beginResetPassword(email)
  }

  private handleChooseRole (role: INameValueItem) {
    const query = {} as { redirect: string }
    const params = {
      role: role.name,
      noBtnBackPrev: this.$route.params.noBtnBack,
    } as { role: string, noBtnBackPrev: string, redirect: string }

    if (this.$route.query.redirect) {
      query.redirect = this.$route.query.redirect as string
    }

    this.$router.push({
      name: 'role.entity',
      params,
      query,
    })
  }
}
